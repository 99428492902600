export enum AppPaths {
  Dashboard = '/',
  // Portfolio section start
  Portfolio = '/portfolio/',
  PortfolioPrivateEquityRoute = '/portfolio/private-equity/',
  PortfolioPrivateEquityCompanyRoute = '/private-equity/:companySlug',
  PortfolioConnectedAccountsRoute = '/connected-accounts/',
  PortfolioConnectedAccountsAbsoluteRoute = '/portfolio/connected-accounts/',
  PortfolioAssetOverviewRoute = '/:slug/',
  // Portfolio section end
  Settings = '/settings/',
  SettingsAccount = '/settings/account/',
  SettingsReferrals = '/settings/referrals/',
  Financing = '/financing/',
  FinancingRequest = 'requests/:companySlug/:proposalRequestUuid/',
  FinancingOverview = 'overview/',
  FinancingRequests = 'requests/',
  FinancingMock = 'estimate/',
  FinancingMockProposal = '/financing/estimate/:companySlug/',
  FinancingSecondaries = '/secondaries/',
  ReviewProposal = '/financing/:companySlug/:proposalRequestUuid/review-proposal/',
  PreviewProposal = '/financing/:companySlug/:proposalRequestUuid/preview-proposal/',
  SignContract = '/financing/:companySlug/:proposalRequestUuid/view-contract/',
  WealthManagement = '/wealth-management/',
  // Always in the context of '/wealth-management'
  WealthManagementQuestionnaire = '/wealth-management/questionnaire/:productCategory/',
  WealthManagementWaitList = 'wait-list',
  WealthManagementIntakeCallPending = 'intake-call-pending',
  WealthManagementIntakeCallScheduled = 'intake-call-scheduled',
  WealthManagementOnboarding = 'onboarding/',
  // Always in the context of 'onboarding'
  WealthManagementOnboardingPersonalInformation = 'personal-information/',
  WealthManagementOnboardingCompliance = 'compliance/',
  WealthManagementOnboardingRiskAssessment = 'risk-assessment/',
  WealthManagementOnboardingPayStubs = 'pay-stubs/',

  WealthManagementFailedKYC = 'failed-kyc',
  WealthManagementReview = 'review',
  WealthManagementContract = 'contract',
  WealthManagementApproved = 'approved',

  Tools = '/tools/',
  Secondaries = '/secondaries/',
  SecondariesRequest = '/secondaries/request/',
  PlanComparison = '/tools/equity-planner/plans/',
  // Always in the context of tools
  EquityPlanner = 'equity-planner',
  TaxCalculator = 'tax-calculator',
  ExercisePlanner = 'forecast',
  ExitCalculator = 'exit-calculator',
  AmtCalculator = 'amt-crossover',
  CompensationCalculator = 'compensation-calculator',
  // Auth & Start flow
  Login = '/login/',
  Signup = '/get-started/',
  Callback = '/callback/:provider',

  // Carta integration
  Carta = '/carta/',

  /**
   * @deprecated this has been moved to wealth management
   */
  DeprecatedAdvisory = '/advisory/',
  /**
   * @deprecated this has been moved to wealth management
   */
  DeprecatedWealth = '/wealth/',
  /**
   * @deprecated this has been moved to tools
   */
  DeprecatedPlanning = '/planning/',
}
