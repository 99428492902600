import { FilingType, RiaDealProduct } from '@generated';
import { AffiliationField } from 'src/types/affiliation';

export enum ArticleFeedback {
  NotHelpful = 'Not helpful',
  SomewhatHelpful = 'Somewhat helpful',
  ReallyHelpful = 'Really helpful',
}

export const companiesMarqueeWording = {
  title: `We work with employees from`,
};

export const companyWording = {
  preferredPriceTooltip: {
    content:
      'The price investors paid for one share in the latest round of funding',
  },
  verifiedPreferredPriceTooltip: {
    content: {
      withDate:
        'The price investors paid for one share of {{ companyName }} stock in the latest round of funding on {{ lastFundingRoundDate }}.',
      noDate:
        'The price investors paid for one share of {{ companyName }} stock in the latest round of funding.',
      editRedirect:
        'If this is outdated, you can change this on your grant edit page.',
      editHere: 'If this is outdated, you can change this here.',
    },
  },

  four09ATooltip: {
    content:
      'The value of one share of common stock for taxation purposes. Your company is required to provide you with the most recent value upon request.',
  },
  optionsLeftAfterExercise:
    'This is the options quantity you have left in this grant after subtracting your previously exercised options',
};

export const legalWording = {
  shortDisclaimer: {
    disclaimer1: 'Disclaimer: Secfi Advisory Limited ("',
    secfi: 'Secfi',
    disclaimer2: `") serves as a non-discretionary sub-adviser to certain pooled investment vehicles seeking to acquire exposure to the stock of certain companies held by the employees of such companies who are seeking liquidity. Secfi does not advise such employees (which may include users of this website), nor are such employees customers or clients of Secfi. The financial planning tools on this website are for illustrative purposes only and are not necessarily indicative of future results. No representation is made that results similar to those shown can be achieved. See disclosures at the bottom of the `,
    link: 'main page',
    disclaimer3: ' for additional information.',
    bankDisclosure:
      ' Secfi is a financial technology company and is not a bank. Banking services provided by Blue Ridge Bank N.A.; Member FDIC.',
  },
  privacyDisclaimer:
    'We will never sell your information. We only use your inputs to calculate accurate insights for your financial situation. See more about our Privacy and Security [here]({{ link }}).',
};

export const commonWording = {
  faq: 'Frequently Asked Questions',
  helpCenter: 'Help Center',
  learn: 'Secfi Learn',
  advisory: 'Secfi Advisory',
  advisorsLabel: 'Secfi Equity Strategy team',
  yes: 'Yes',
  no: 'No',
  beepBoop: 'Beep boop',
  crunchingNumbers: 'crunching numbers',
};

export const authenticationContextErrors = {
  existingAccountWithEmail: `An account already exists with this email address.\n\n Please proceed with regular Sign In.`,
  unknownError: `An unknown error occurred.\n\n  Please contact our support team.`,
  unverifiedAccount:
    'Your account has been created! Please verify your email address using the link you received.',
};

export const signUpSignIn = {
  alreadyHaveAccount: 'Already have an account?',
  loginLinkText: 'Sign in',
  noAccount: "Don't have an account?",
  signUpLinkText: 'Sign up',
};

export const grantSelector = {
  legend: {
    sold: 'Sold',
    exercisableOrVested: 'Exercisable/Vested',
    exercisable: 'Exercisable',
    vested: 'Vested',
    unvested: 'Unvested',
    toVest: 'To vest',
    shares: 'Shares',
  },
};

export const noAvailableOptionsDisclaimer = {
  heading: 'You have no exercisable options in your account',
  description:
    'Please review your equity information to ensure you have valid ISO or NSO grants in order to use this tool.',
  linkText: 'Review equity details',
};

export const noAvailableIsosDisclaimer = {
  heading: 'You have no exercisable ISOs in your account',
  description:
    'Please review your equity information to ensure you have valid ISO grants in order to use this tool.',
  linkText: 'Review equity details',
};

export const otherIncomeWording = {
  otherIncomeOption: 'Do you have any other sources of income?',
  selfEmploymentIncome: '1099 Income',
  dividendIncome: 'Dividend income',
  shortTermCapitalGains: 'Short-term capital gains / (loss)',
  netRentalRealEstateIncome: 'Net rental real estate income',
  longTermCapitalGains: 'Long-term capital gains / (loss)',
  additionalIncome: 'Any additional sources of income',
};

export const taxDeductionsWording = {
  four01kContributions: '401K contributions',
  propertyStateLocalTaxes: 'Property tax',
  mortgageInterest: 'Mortgage interest',
  charitableContributions: 'Charitable contributions',
  iraContribution: 'IRA contributions',
  medicalExpenses: 'Medical expense',
  additionalDeductions: 'Any additional deductions',
};

export const securitySeeHowWording = {
  seeHowText: `Your information is always secure with us. `,
  seeHowButton: `See how`,
};

export const helpfulResourceWording = {
  title: `How helpful was this article?`,
  feedback: Object.values(ArticleFeedback),
  thankYou: `Thanks for your feedback!`,
};

export const filingTypesWording = {
  single: 'Single',
  marriedJointly: 'Married - filing jointly',
  marriedSeparately: 'Married - filing separately',
  headOfHousehold: 'Head of household',
};

export const supportWording = {
  heading: 'Support',
  strategyTeam: 'Secfi equity strategy team',
};

export const appointmentWording = {
  scheduleMeetingButtonTitle: 'Schedule meeting',
  rescheduleMeetingButtonTitle: 'Reschedule',
};

export const productEntrypointWording = {
  title: `Get 1:1 guidance from experts`,
  wealthCard: {
    title: `Align your equity with your financial goals`,
    text: [
      `Build a plan for home ownership, retirement, your kid’s education, passion projects, or whatever’s important to you. `,
      `Our team of advisors can help you with equity planning, financial advice and investment management.`,
    ].join('\n'),
  },
  financingCard: {
    title: `Exercise your stock options without paying out of pocket`,
    text: [
      `Think your company's got potential and want a piece of the pie? Get help covering your stock option exercise costs, `,
      `including taxes, with one-on-one guidance from our Equity Strategists along the way.`,
    ].join('\n'),
  },
};

export const calculatorsContainerWording = {
  title: `Calculate your taxes`,
  multiAffiliationTitle: `Calculate your taxes for the grants at`,
};

export const learnArticlesWording = {
  linkText: `See all articles`,
  link: `https://secfi.com/learn`,
  articles: [
    {
      eyebrow: `Stock options`,
      title: `My company is going public or getting acquired`,
      url: `https://secfi.com/learn/collection/my-company-is-going-public-or-getting-acquired`,
      color: `blue`,
      image: 'learn-going-public.png',
    },
    {
      eyebrow: `Learn`,
      title: `Managing wealth with stock options`,
      url: `https://secfi.com/learn/collection/managing-wealth-with-stock-options`,
      color: `teal`,
      image: 'learn-manage-wealth.png',
    },
    {
      eyebrow: `Stock options`,
      title: `Exercising stock options`,
      url: `https://secfi.com/learn/collection/exercising-stock-options`,
      color: `dark-brown`,
      image: 'learn-exercise-options.png',
    },
    {
      eyebrow: `Learn`,
      title: `Starting a new job with stock options`,
      url: `https://secfi.com/learn/collection/starting-a-new-job-with-stock-options`,
      color: `sand`,
      image: 'learn-new-job.png',
    },
  ],
};

export const copyToClipboardWording = {
  copied: 'Copied to clipboard',
  copyGeneric: 'Copy {{ generic }}',
};

export const getProductName = (product: RiaDealProduct | undefined) => {
  switch (product) {
    case undefined:
      return '';
    case RiaDealProduct.EquityAndTaxPlanning:
      return 'Equity and Tax Planning';
    case RiaDealProduct.InvestmentManagement:
      return 'Wealth Management';
    case RiaDealProduct.IpoAndLiquidityPlanning:
      return 'IPO and Liquidity Planning';
    default:
      throw new Error(`onboarding title not implented for product: ${product}`);
  }
};

export const allocationTableWording = {
  assets: 'Assets breakdown',
  addAnotherAsset: 'Add another asset',
  // Table header cells
  headers: {
    type: 'Type',
    weight: '%',
    quantity: 'Quantity',
    percentage: '%',
    value: 'Value',
  },
  // Table Row cells
  cells: {
    type: {
      CASH: 'Cash',
      CRYPTO: 'Crypto',
      DERIVATIVE: 'Derivatives',
      ETF: 'ETFs',
      LOAN: 'Loans',
      FIXED_INCOME: 'Fixed Income',
      MUTUAL_FUND: 'Mutual Funds',
      OTHER: 'Others',
      PRIVATE_EQUITY: 'Equity in {{name}}',
      STOCK: 'Stocks',
      UNKNOWN: 'Unknown',
    },
    value: {
      info: 'Based on what’s vested and {{ type }} price',
    },
  },
  equityIn: 'Equity in',
};

export const settingItemsWording = {
  account: 'Account',
  equityManagement: 'Equity details',
  tax: 'Tax details',
  affiliation: 'Employment details',
  connections: 'Manage connections',
  referrals: 'Referrals',
};

export const referralBanner = {
  buttonLabel: 'Refer a friend',
  wealthBold: 'Enjoying being a Wealth client?',
  wealthDetails: 'Refer your friends for up to $500',
};

export const affiliationFormWording = {
  warning:
    'Our products currently cater to the tax requirements of United States taxpayers.',
  company: {
    title: 'Company valuation',
    subtitle:
      'The Fair Market Value (409A) and Preferred Price help us calculate taxes and the current value of your equity.',
    fields: {
      fmv: {
        label: 'Most recent FMV',
        placeholder: '0.00',
        tooltip:
          'The FMV or 409A is the current value of one share of common stock. You can find it in Carta or Shareworks or request it from your company.',
      },
      prefPrice: {
        label: 'Most recent pref price',
        placeholder: '0.00',
        tooltip:
          'The pref price is what investors paid for one share in the latest round of funding. You can find it on sites such as Pitchbook or Crunchbase.',
      },
    },
  },
  employment: {
    title: 'Employment information',
    subtitle:
      'Your employment information helps us estimate taxes based on state tax laws. Please fill in the country and state you lived in the longest during your employment at {{ companyName }}.',
    fields: {
      startDate: {
        label: 'Employment start date',
        name: 'startDate',
      },
      endDate: {
        label: 'Employment end date',
        name: 'endDate',
      },
    },
  },
};

export const taxFormContainerWording = {
  formLabels: {
    filingTypes: 'How do you file your taxes?',
    income: 'Gross income before taxes',
    subdivision: "Where's your permanent residence?",
    dependents: 'Number of dependents',
  },
  filingTypes: [
    {
      value: FilingType.Single,
      text: 'Single',
    },
    {
      value: FilingType.MarriedJoint,
      text: 'Married',
      helperText: 'Filing taxes jointly',
    },
    {
      value: FilingType.MarriedSeparate,
      text: 'Married',
      helperText: 'Filing taxes separately',
    },
    {
      value: FilingType.HeadOfHousehold,
      text: 'Head of household',
    },
  ],
  itemizeDeductions: {
    label: 'Do you want to itemize your deductions?',
    choices: [
      {
        value: 'true',
        text: commonWording.yes,
      },
      {
        value: 'false',
        text: commonWording.no,
      },
    ],
  },
  otherIncomeOption: {
    label: 'Do you have any other sources of income?',
    choices: [
      {
        value: 'true',
        text: commonWording.yes,
      },
      {
        value: 'false',
        text: commonWording.no,
      },
    ],
  },
};

export const createNewAffiliationWording = {
  success: 'Your company has been added!',
  error: {
    affiliation:
      "Sorry, something went wrong and we couldn't save your information.",
    company: "Sorry, something went wrong and we couldn't add your company.",
  },
};

export const residenceFormWording = {
  country: {
    label: 'Country of residence',
    name: 'country',
    placeholder: 'Select',
  },
  city: {
    label: 'Do you live in New York City?',
    options: [
      {
        id: 'nyc-yes',
        value: 'NY',
        text: 'Yes',
      },
      {
        id: 'nyc-no',
        value: 'none',
        text: 'No',
      },
    ],
  },
  subdivision: {
    label: 'State of residence',
    placeholder: 'Select',
    name: 'subdivision',
  },
};

export const affiliationSummaryWording = {
  text: 'Edit',
  card: {
    [AffiliationField.FairMarketValue]: {
      label: 'FMV',
      text: 'Add',
      value: null,
    },
    [AffiliationField.PrefPrice]: {
      label: 'Preferred price',
      text: 'Add',
      value: null,
    },
    [AffiliationField.State]: {
      label: 'Country',
      text: 'Enter country',
      value: null,
    },
    [AffiliationField.EmploymentDuration]: {
      label: 'Employment start / end date',
      text: 'Enter date',
      value: null,
    },
  },
};

export const manageCartaConnectionWording = {
  yourCartaAccountIsConnected: 'Your Carta account is connected',
  lastUpdated: 'Last updated: {{ date }}',
  somethingWentWrong: 'Something went wrong, please try again',
  success: 'Grants synced successfully',
  pleaseWaitForCurrentSyncToFinish:
    'Please wait for the current sync to finish',
  youDidntSelectPortfolios: 'You did not select any portfolio(s) to link',
};

export const plaidIntegrationWording = {
  ctaButton: 'Connect a bank account',
  genericError: 'Sorry, the connection has failed. Please try again',
  successMessage: 'Account connected successfully!',
  manageYourConnections: 'Manage your connections',
  updatedAt: 'Last updated:',
  successMessageReconnect: 'Account updated successfully!',
  portfolioOutOfSync:
    'The following {{ accounts }} disconnected: {{ institutions }}.',
  reconnect: 'Reconnect',
  errorMessage: 'Sorry, the connection has failed. Please try again',
};

export const manageAffiliationContainerWording = {
  title: 'Edit employment details',
  label: 'Select a company',
  emptyState: {
    title: `First, let's add your company equity`,
    text: `Add your stock options (ISO/NSO), RSUs or shares to access free tools and see if you are pre-approved for financing.`,
    button: `Get started`,
  },
};

export const addAffiliationWording = {
  title: `Let's add your company details`,
  button: 'Submit',
};

export const personalInformationFormWording = {
  sections: { details: 'Your details', residence: 'Primary residence' },
  checkbox: {
    label: 'Country of tax residence',
    text: 'Use the same as citizenship',
  },
  labels: {
    yourDetails: 'Your details',
    name: 'Full legal name',
    citizenship: 'Citizenship',
    ssn: 'Social Security Number',
    taxResidence: 'Country of tax residence',
    useSame: 'Use the same as citizenship',
    birthday: 'Date of birth',
    maritalStatus: 'Marital status',
    phone: 'Phone number',
    email: 'Personal email address',
    primaryResidence: 'Primary residence',
    address: 'Street address',
    address2: 'Apt, suite, building',
    postalCode: 'Zip code',
    city: 'City',
    state: 'State',
    country: 'Country',
  },
};
