import { ApolloError, ApolloQueryResult } from '@apollo/client';
import {
  EquityShareGrantFragment,
  GetAllGrantsQuery,
  GetAllGrantsQueryVariables,
  RsuGrantFragment,
  ShareGrantSource,
  StockOptionGrantFragment,
  useGetAllGrantsQuery,
} from '@generated';
import { useMemo } from 'react';
import { AllEquityGrantFragments } from 'src/types/grants';

interface UseGrantsReturn {
  stockOptionGrants: StockOptionGrantFragment[];
  rsuGrants: RsuGrantFragment[];
  shareGrants: EquityShareGrantFragment[];
  combinedOptionsAndRsus: (StockOptionGrantFragment | RsuGrantFragment)[];
  allGrants: AllEquityGrantFragments[];
  grantsQuery: GetAllGrantsQuery | undefined;
  isLoading: boolean;
  error: ApolloError | undefined;
  refetch: (
    variables: GetAllGrantsQueryVariables
  ) => Promise<ApolloQueryResult<GetAllGrantsQuery>>;
}

interface UseGrantsArgs {
  customerUuid: string | null | undefined;
  affiliationUuid?: string;
  onCompleted?: (data: GetAllGrantsQuery) => void;
  onError?: (error: ApolloError) => void;
  skip?: boolean;
  debug?: {
    log?: boolean;
    callsite?: string;
  };
  onlyEditableShareGrants?: boolean;
  sortBy?: keyof SortableFields;
  sortOrder?: 'ASC' | 'DESC';
}

type SortableFields = Pick<
  AllEquityGrantFragments,
  'createdAt' | 'updatedAt' | 'vestingStartDate' | 'vestingEndDate'
>;

interface SortGrantsArgs {
  grants: AllEquityGrantFragments[];
  sortBy: keyof SortableFields;
  sortOrder: 'ASC' | 'DESC';
}

const sortGrants = ({ grants, sortBy, sortOrder }: SortGrantsArgs) => {
  return grants.sort((a, b) => {
    if (sortOrder === 'ASC') {
      return a[sortBy] < b[sortBy] ? -1 : 1;
    } else {
      return a[sortBy] > b[sortBy] ? -1 : 1;
    }
  });
};

export const useGrants = ({
  customerUuid: customer,
  affiliationUuid: affiliation,
  onCompleted,
  onError = (error: ApolloError) => {
    // eslint-disable-next-line no-console -- debug
    console.log('useGrants onError', error);
  },
  skip: skipProp,
  debug,
  onlyEditableShareGrants = false,
  sortBy = 'createdAt',
  sortOrder = 'DESC',
}: UseGrantsArgs): UseGrantsReturn => {
  if (debug?.log) {
    // eslint-disable-next-line no-console -- debug
    console.log(
      `%cuseGrants ${debug?.callsite ? `@${debug?.callsite}` : ''}`,
      'color: #ff00ff'
    );
  }

  const { data: grantsQuery, loading, error, refetch } = useGetAllGrantsQuery({
    variables: customer ? { customer, affiliation } : undefined,
    skip: skipProp || !customer,
    onCompleted,
    onError,
  });

  return useMemo(() => {
    const baseData = grantsQuery?.equity?.getAllGrants;
    const stockOptionGrants = baseData?.stockOptionGrants ?? [];
    const rsuGrants = baseData?.rsuGrants ?? [];
    const returnedShareGrants = baseData?.shareGrants ?? [];

    let shareGrants = returnedShareGrants;
    if (onlyEditableShareGrants) {
      // In editing view we're focusing on editing exercise events,
      // so exercised share grants are skipped.
      shareGrants = returnedShareGrants.filter(
        (grant) => grant.source !== ShareGrantSource.Exercise
      );
    }

    const combinedOptionsAndRsus = [...stockOptionGrants, ...rsuGrants];
    const grants = [...combinedOptionsAndRsus, ...shareGrants];

    const allGrants = sortGrants({ grants, sortBy, sortOrder });

    return {
      stockOptionGrants,
      rsuGrants,
      shareGrants,
      combinedOptionsAndRsus,
      allGrants,
      grantsQuery,
      isLoading: loading,
      error,
      refetch,
    };
  }, [grantsQuery]);
};
