import { ReactElement } from 'react';

export enum AffiliationField {
  PrefPrice = 'preferred_price',
  FairMarketValue = 'fair_market_value',
  State = 'state',
  EmploymentDuration = 'employment_duration',
}

export interface AffiliationDetail {
  label: string;
  text: string;
  value: string | ReactElement | null;
  tooltip?: string;
}

export interface AffiliationSummary {
  uuid: string;
  name?: string;
  logo?: string;
}
