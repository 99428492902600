import { createContext } from 'react';

import type { AuthenticationService } from './types';

export const AuthenticationServiceContext = createContext<AuthenticationService | null>(
  null
);

export const AuthenticationServiceProvider =
  AuthenticationServiceContext.Provider;
