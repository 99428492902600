import { useGetAllFinancingObjectsQuery } from '@generated';
import { useAuthenticatedUser } from 'src/services/authentication/hooks';
import { UUID } from 'src/types/basic';
import { AllFinancingObjectsItem } from 'src/types/financing';
import { extractNonNullItems } from 'src/utils/extractNonNullItems';

interface UseGetFinancingObjectsResult {
  isLoading: boolean;
  financingObjects: AllFinancingObjectsItem[];
  refetchFinancingObjects: () => void;
}

export const useGetFinancingObjects = (
  affiliations: UUID[]
): UseGetFinancingObjectsResult => {
  const { user: authenticatedUser } = useAuthenticatedUser();
  const ownerUuid = authenticatedUser?.uuid;

  const { data, loading: isLoading, refetch } = useGetAllFinancingObjectsQuery({
    skip: !ownerUuid,
    variables: {
      filters: { owner: ownerUuid },
    },
  });

  // this filtering is required for testing purposes
  // the query can return null items for financingObjects made in different BE
  const financingObjects = extractNonNullItems(data?.financingObjects?.nodes);
  // this filtering is needed, because BE leaves orphaned financing objects when affiliation gets deleted
  const financingObjectsFiltered = financingObjects
    .filter((item) => {
      const affiliationId = item?.proposal_request?.affiliation?.uuid;

      return affiliationId && affiliations.includes(affiliationId);
    })
    .sort((a, b) => {
      const aName = a.proposal_request.affiliation?.company?.name;
      const bName = b.proposal_request.affiliation?.company?.name;
      return aName?.localeCompare(bName ?? '') ?? 0;
    });

  return {
    isLoading,
    financingObjects: financingObjectsFiltered,
    refetchFinancingObjects: refetch,
  };
};
