import { useReactiveVar } from '@apollo/client';
import { useContext } from 'react';

import { AuthenticationServiceContext } from './AuthenticationServiceContext';
import type { AuthenticationService } from './types';

export const useAuthenticationService = (): AuthenticationService => {
  const service = useContext(AuthenticationServiceContext);
  if (service === null) {
    throw new Error(
      'no AuthenticationServiceProvider found. make sure to wrap the app in <AuthenticationServiceProvider>'
    );
  }
  return service;
};

export const useAuthenticationServiceState = () => {
  const service = useAuthenticationService();
  const state = useReactiveVar(service.store);
  return state;
};

export const useAuthenticatedUser = () => {
  const { user, impersonatee } = useAuthenticationServiceState();
  return { user: impersonatee ?? user, isMirroring: impersonatee !== null };
};
